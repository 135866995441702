import React from "react"
import { graphql } from "gatsby"
import { media, styled } from "../components/mediaCSS"
import { FaPizzaSlice } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaFilePdf } from "react-icons/fa"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const TopContainer = styled.div`
  margin-top: 30px;
  ${media.tablet_desktop`
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  > * {
    margin: 0px 10px; 

  }

  `}
`

const MenuContainer = styled.div``

const BuffetContainer = styled.div`
  color: white;
  text-align: center;
  ${media.tablet_desktop`
    width: 50%;
  `}

  h3 {
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.75);
    margin: 0px;
  }

  ul {
    padding: 5px 20px;
    background: rgba(0, 0, 0, 0.5);
    margin-top: 80px;
    list-style-type: none;
    font-size: 16px;
  }
`

const FoodGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  h3 {
    margin: 5px 0px;
    width: 100%;
  }
`

// const FoodItemsContainer = styled.div`
//   flex: 1;
//   display: flex;

//   justify-content: center;
// `

const FoodItemContainer = styled.div`
  ${media.tablet_desktop`
  width: 25%;
  `}
  padding: 0px 20px;

  h4 {
    margin: 5px 0px;
  }

  img {
    border-radius: 5px;
  }
`

const PizzaImageContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0px;

  h5 {
    margin: 5px 0px;
  }

  > * {
    width: 35%;
  }
`

const PDFContainer = styled.div`
  background: #e51937;
  color: white;
  border-radius: 5px;
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet_desktop`
    width: 50%;
  `}


  font-size: 18px;
  align-items: center;
  > * {
    width: 90%;
   text-align: center;
  }

  
  }

  a {
    color: white;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.5);
    margin: 3px 10px;
    padding: 6px;
    border-radius: 5px;
  }

`

export default ({ data }) => {
  const {
    menuPizza,
    homePizza,
    pizzaPepperoni,
    pizzaBBQ,
    pizzaMeatLovers,
    pizzaSupreme,
  } = data

  const foods = data.newmenu.nodes.filter(
    n => n.key !== "PIZZA" && n.key !== "ADDONS"
  )

  return (
    <Layout>
      <SEO title="Menu" />
      <TopContainer>
        <BuffetContainer>
          <BackgroundImage
            Tag="article"
            fluid={homePizza.childImageSharp.fluid}
            backgroundColor={`#2e1e21`}
          >
            <h3>Lunch Buffet</h3>
            <ul>
              <li>Our Lunch Buffet is back! </li>
              <li>11-2 PM Monday - Saturday </li>
              <li>$15 Pizza Buffet w/ Drink</li>
            </ul>
          </BackgroundImage>
        </BuffetContainer>
        <PDFContainer>
          <span>
            <FaFilePdf></FaFilePdf> Downloads
          </span>
          <a
            aria-label="Menu"
            target="_blank"
            rel="noreferrer"
            href={`/Menu.pdf`}
          >
            Menu
          </a>

          <a
            aria-label="Menu"
            target="_blank"
            rel="noreferrer"
            href={`/Mamas-Pizza-Nutrition-Facts.pdf`}
          >
            Nutrition Facts
          </a>

          <a
            aria-label="Menu"
            target="_blank"
            rel="noreferrer"
            href={`/Mamas-Pizza-Ingredients-Lists-with-Brands.pdf`}
          >
            Ingredients
          </a>
          <a
            aria-label="Menu"
            target="_blank"
            rel="noreferrer"
            href={`/GFcrust.pdf`}
          >
            Gluten Free / Cauliflower Crust info
          </a>
        </PDFContainer>
      </TopContainer>
      <h3>Pizzas:</h3>
      <Img
        style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }}
        fluid={menuPizza.childImageSharp.fluid}
      />

      <PizzaImageContainer>
        <div>
          <Img
            title="Pepperoni Pizza"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "0 auto",
            }}
            fluid={pizzaPepperoni.childImageSharp.fluid}
          />
          <h5>Pepperoni Pizza</h5>
        </div>

        <div>
          <Img
            title="BBQ Pizza"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "0 auto",
            }}
            fluid={pizzaBBQ.childImageSharp.fluid}
          />
          <h5>BBQ Pizza</h5>
        </div>

        <div>
          <Img
            title="Meat Lovers Pizza"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "0 auto",
            }}
            fluid={pizzaMeatLovers.childImageSharp.fluid}
          />
          <h5>Meat Lover's Pizza</h5>
        </div>

        <div>
          <Img
            title="Combo / Supreme Pizza"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "0 auto",
            }}
            fluid={pizzaSupreme.childImageSharp.fluid}
          />
          <h5>Combo / Supreme Pizza</h5>
        </div>
      </PizzaImageContainer>
      <MenuContainer>
        {foods.map(item => (
          <FoodGroupContainer>
            <h3>{item.name}</h3>
            {item.items
              .filter(i => i.active)
              .map(child => {
                const itemImage = data["pic" + child.key]

                return (
                  <FoodItemContainer key={child.key}>
                    {itemImage && (
                      <Img
                        style={{
                          width: "100%",
                          maxWidth: "500px",
                          margin: "0 auto",
                        }}
                        fluid={itemImage.childImageSharp.fluid}
                      />
                    )}
                    <h4>{child.name}</h4>
                    <div>{child.description}</div>
                    {child.basePrice > 0 && (
                      <div>${Number(child.basePrice).toFixed(2)}</div>
                    )}
                  </FoodItemContainer>
                )
              })}
          </FoodGroupContainer>
        ))}
      </MenuContainer>
    </Layout>
  )
}

export const query = graphql`
  query MenuQuery {
    pizzaPepperoni: file(relativePath: { eq: "pepperoni_pizza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pizzaMeatLovers: file(relativePath: { eq: "meatlovers_pizza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pizzaSupreme: file(relativePath: { eq: "supreme_combo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pizzaBBQ: file(relativePath: { eq: "bbq_pizza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homePizza: file(relativePath: { eq: "homePizza.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    menuPizza: file(relativePath: { eq: "menu.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picWINGS: file(relativePath: { eq: "menu/wings.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picREGSALAD: file(relativePath: { eq: "menu/regularsalad.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picITALIANSALAD: file(relativePath: { eq: "menu/italiansalad.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picGCHICKENSALAD: file(relativePath: { eq: "menu/salad.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picGCSANDWICH: file(relativePath: { eq: "menu/grilledcheese.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picHAMCSANDWICH: file(relativePath: { eq: "menu/hamandcheese.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picPEPPERONISUB: file(relativePath: { eq: "menu/pepsub.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picMAMASUB: file(relativePath: { eq: "menu/mamassub.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picMEATBALLSUB: file(relativePath: { eq: "menu/meatballsub.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picBREADSTIX: file(relativePath: { eq: "menu/breadstix.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCHEESESTIXSIZES: file(relativePath: { eq: "menu/cheesestix.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCHEESESTIX: file(relativePath: { eq: "menu/cheesestix.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCOOKIE: file(relativePath: { eq: "menu/cookie.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCAKE_CFUDGE: file(relativePath: { eq: "menu/cake_cfudge.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCAKE_RAINBOW: file(relativePath: { eq: "menu/cake_rainbow.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCAKE_CONFETTI: file(relativePath: { eq: "menu/cake_confetti.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    picCAKE_REDVELVET: file(relativePath: { eq: "menu/cake_redvelvet.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newmenu: allMenu {
      nodes {
        key
        name
        items {
          name
          key
          basePrice
          description
          options {
            name
          }
          active
        }
      }
    }
  }
`
